import React from 'react';
import PropTypes from 'prop-types';
import { FONT } from 'UI/globals/colours';
import {
  MC as MC_GLOBAL,
  SD as SD_GLOBAL,
  PBP as PBP_GLOBAL,
  TP as TP_GLOBAL,
  DP as DP_GLOBAL,
  FP as FP_GLOBAL,
  DMC as DMC_GLOBAL,
  DO as DO_GLOBAL,
  DOT as DOT_GLOBAL,
  SBH as SBH_GLOBAL,
  LTE as LTE_GLOBAL,
  DT as DT_GLOBAL,
  CON as CON_GLOBAL,
  IMAGE_CONTAINER,
} from 'CORE__UI/apps/PromotionsDescriptionApp/core__promotionsDescriptionPage';
import {
  MC as MC_LOCAL,
  SD as SD_LOCAL,
  PBP as PBP_LOCAL,
  TP as TP_LOCAL,
  DP as DP_LOCAL,
  FP as FP_LOCAL,
  DMC as DMC_LOCAL,
  DO as DO_LOCAL,
  DOT as DOT_LOCAL,
  SBH as SBH_LOCAL,
  LTE as LTE_LOCAL,
  DT as DT_LOCAL,
  CON as CON_LOCAL,
} from 'UI/apps/PromotionsDescriptionApp/PromotionsDescriptionPage';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import PubSub from 'Services/pubsub/core__pubsub';
import { HnaldeDefaultCurrency } from 'Services/global/core__currency-format';
import { SYMBOLS } from 'Services/session/core__session.utils';
import { HanldeCMSCurrencySymbol } from 'Services/global/core__currency-format';
import { getComponent } from 'Services/core__imports';

const MC = getComponent(MC_LOCAL, MC_GLOBAL);
const SD = getComponent(SD_LOCAL, SD_GLOBAL);
const PBP = getComponent(PBP_LOCAL, PBP_GLOBAL);
const TP = getComponent(TP_LOCAL, TP_GLOBAL);
const DP = getComponent(DP_LOCAL, DP_GLOBAL);
const FP = getComponent(FP_LOCAL, FP_GLOBAL);
const DMC = getComponent(DMC_LOCAL, DMC_GLOBAL);
const DO = getComponent(DO_LOCAL, DO_GLOBAL);
const DOT = getComponent(DOT_LOCAL, DOT_GLOBAL);
const SBH = getComponent(SBH_LOCAL, SBH_GLOBAL);
const LTE = getComponent(LTE_LOCAL, LTE_GLOBAL);
const DT = getComponent(DT_LOCAL, DT_GLOBAL);
const CON = getComponent(CON_LOCAL, CON_GLOBAL);

class PromotionsDescriptionPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currencyCode: HnaldeDefaultCurrency(),
    };
  }
  componentDidMount = () => {
    // when user login
    this.sessionCreatedListener = PubSub.listen(
      PubsubEvents.SESSION_CREATED,
      () => {
        const UserAuthData = FSBCustomerSession.getSession();
        if (UserAuthData && UserAuthData['accessToken']) {
          this.setState({ currencyCode: UserAuthData.currencyCode });
        }
      }
    );
    // when user relogin or reload page
    this.sessionUpdatedListener = PubSub.listen(
      PubsubEvents.SESSION_UPDATED,
      () => {
        const UserAuthData = FSBCustomerSession.getSession();
        if (UserAuthData && UserAuthData['accessToken']) {
          this.setState({ currencyCode: UserAuthData.currencyCode });
        }
      }
    );
    // if user is already login
    const UserAuthData = FSBCustomerSession.getSession();
    if (UserAuthData && UserAuthData['accessToken']) {
      this.setState({ currencyCode: UserAuthData.currencyCode });
    }
  };

  componentWillUnmount() {
    this.sessionCreatedListener?.unsubscribe();
    this.sessionUpdatedListener?.unsubscribe();
  }

  render() {
    const { data, showJNPromo } = this.props;
    // to make the currency symbol dynamic
    const symbol = SYMBOLS[this.state.currencyCode];
    const {
      bannertitle,
      bannerdescription,
      bannerfootertext,
      palette,
      bannerimageurl,
      buttonlabel,
      buttonlink,
      descriptiontitle,
      descriptioncontent,
      casinotheme,
    } = data;
    const isHtml = /<\/?[a-z][\s\S]*>/i.test(descriptioncontent);
    const formattedContentDescription = descriptioncontent?.split('|');
    const displayDescriptionContent = () => {
      if (isHtml) {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: HanldeCMSCurrencySymbol(descriptioncontent, symbol),
            }}
          />
        );
      } else {
        if (showJNPromo && formattedContentDescription.length > 1) {
          return formattedContentDescription.map((condition, index) => {
            return <p key={index}>{condition}</p>;
          });
        } else {
          return HanldeCMSCurrencySymbol(descriptioncontent, symbol);
        }
      }
    };
    return (
      <MC>
        {(bannerimageurl ||
          bannertitle ||
          bannerfootertext ||
          bannerdescription) && (
          <SD //promo banner container
            backgroundColor={
              palette === GLOBAL_CONSTANTS.LIGHT ? FONT.secondary : FONT.primary
            }
            fontColor={
              palette === GLOBAL_CONSTANTS.LIGHT ? FONT.primary : FONT.secondary
            }
          >
            {bannerimageurl && <IMAGE_CONTAINER src={bannerimageurl} />}
            <CON>
              {(bannertitle || bannerdescription) && (
                <PBP>
                  {bannertitle && <TP>{bannertitle}</TP>}
                  {bannerdescription && (
                    <DP>
                      {HanldeCMSCurrencySymbol(bannerdescription, symbol)}
                    </DP>
                  )}
                </PBP>
              )}
              {bannerfootertext && <FP>{bannerfootertext}</FP>}
            </CON>
          </SD>
        )}
        {buttonlabel && buttonlink && (
          <React.Fragment>
            <SBH
              casinoTheme={casinotheme}
              backgroundColor={
                palette === GLOBAL_CONSTANTS.LIGHT
                  ? FONT.secondary
                  : FONT.primary
              }
            >
              <LTE href={data.buttonlink}>{data.buttonlabel}</LTE>
            </SBH>
            <br />
            <br />
          </React.Fragment>
        )}
        {descriptiontitle && (
          <DT
            casinoTheme={casinotheme}
            fontColor={
              palette === GLOBAL_CONSTANTS.LIGHT ? FONT.secondary : FONT.primary
            }
          >
            {HanldeCMSCurrencySymbol(descriptiontitle, symbol)}
          </DT>
        )}
        {descriptioncontent && (
          <DMC>
            <DOT>
              <DO>{displayDescriptionContent()}</DO>
            </DOT>
          </DMC>
        )}
      </MC>
    );
  }
}

PromotionsDescriptionPage.propTypes = {
  data: PropTypes.object,
  showJNPromo: PropTypes.bool,
  currencyCode: PropTypes.string,
};
export default PromotionsDescriptionPage;
