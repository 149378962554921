import {
  MC,
  PH,
  BA,
  PT,
  SD,
  PBP,
  TP,
  DP,
  FP,
  DMC,
  DO,
  DOT,
  FTC,
} from 'CORE__UI/apps/PromotionsDescriptionApp/core__promotionsDescriptionPage';
import styled from 'styled-components';
import { GREYS } from '../../globals/colours';
const MC_JN = styled(MC)`
  width: 100%;
  max-width: 1024px;
  margin-top: 8px;
`;
const DMC_GDWN = styled(DMC)`
  color: ${GREYS.white};
`;
const SD_GDWN = styled(SD)`
  background-color: ${GREYS.doveD};
`;
const TP_GDWN = styled(TP)`
  color: ${GREYS.silver};
`;
const DP_GDWN = styled(DP)`
  color: ${GREYS.silver};
`;
const FP_GDWN = styled(FP)`
  color: ${GREYS.white};
`;
export {
  MC_JN as MC,
  PH,
  BA,
  PT,
  SD_GDWN as SD,
  PBP,
  TP_GDWN as TP,
  DP_GDWN as DP,
  FP_GDWN as FP,
  DMC_GDWN as DMC,
  DO,
  DOT,
  FTC,
};
