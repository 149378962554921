import React from 'react';
import { PromotionsDescriptionPage } from '../component/components/PromotionsDescriptionPage';
import { FSBTheme } from 'Services/core__fsb-theme';
import PropTypes from 'prop-types';

class PromotionsDescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: props?.appConfig.theme,
    };
  }
  render() {
    return (
      <FSBTheme theme={this.state.theme}>
        <PromotionsDescriptionPage
          {...this.props}
          data={this.props?.appConfig}
        />
      </FSBTheme>
    );
  }
}
PromotionsDescription.propTypes = {
  theme: PropTypes.string,
  appConfig: PropTypes.object,
};
export default PromotionsDescription;
