import React from 'react';
import ReactDOM from 'react-dom';
import PromotionsDescription from '../PromotionsDescriptionApp/component/core__PromotionsDescriptionReact';
import ScDecoder from 'Services/json/core__decoder';
import { TranslationsProvider } from 'Services/translations/core__translations';
export const Translations = new TranslationsProvider(
  'PromotionsDescriptionApp'
);

class PromotionsDescriptionApp {
  constructor(properties) {
    Object.assign(this, properties);

    if (this.nodeSSR) return;
    const reduxSelector = document.querySelector(`#redux${this.appSection.id}`);
    reduxSelector
      ? this.start(
          new ScDecoder(JSON.parse(reduxSelector.innerHTML)),
          reduxSelector
        )
      : this.start();
  }

  start(data, reduxSelector) {
    const ReactInstance = this.getReactApp();
    const jsx = <ReactInstance appConfig={this.appConfig} />;

    ReactDOM[data ? 'hydrate' : 'render'](jsx, this.appSection);
    if (reduxSelector) reduxSelector.remove();
  }

  getReactApp() {
    return PromotionsDescription;
  }
}

export default PromotionsDescriptionApp;
