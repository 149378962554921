import styled from 'styled-components';
import { FONT, GREYS, SUPPLEMENTARY } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

//Main container div
export const MC = styled.div`
  max-width: 1024px;
  margin 0 auto;
  user-select: text;
  @media (max-width: ${BREAKPOINT_S}) {
    width: 100%;
  }  
`;

//div for page heading
export const PH = styled.div`
  display: flex;
  align-items: center;
  height: 55px;
  padding: 0;
`;

//a for back arrow
export const BA = styled.a`
  margin-right: 12px;
  font-size: 22px;
`;

//a for promotions title
export const PT = styled.a`
  font-size: 12px;
  font-weight: 400;
  color: ${FONT.dark};
`;

//empty div
export const CON = styled.div`
  width: inherit;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
`;

export const PBP = styled.div``;

//div for promotions title
export const TP = styled.div`
  padding: 16px 16px 12px;
  font-size: 14px;
  color: ${({ theme }) => (theme.dark ? GREYS.silver : GREYS.black)};
`;

//div for promotions description
export const DP = styled.div`
  padding: 0px 16px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  width: 350px;
  letter-spacing: normal;
  color: ${({ theme }) => (theme.dark ? GREYS.silver : GREYS.black)};
  @media screen and (max-width: ${BREAKPOINT_S}) {
    width: 100%;
  }
`;

//div for promotions footer
export const FP = styled.p`
  position: absolute;
  width: 100%;
  bottom: 0;
  min-height: 30px;
  padding: 0 16px;
  background-color: rgba(34, 34, 34, 0.4);
  display: flex;
  align-items: center;
  color: ${({ theme }) => (theme.dark ? GREYS.white : FONT.light)};
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.35px;
`;

//Main container for each promotion
export const SD = styled.div`
  position: relative;
  background-color: ${({ backgroundColor, theme }) =>
    theme.dark ? GREYS.doveD : backgroundColor};
  color: ${props => props.fontColor};
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  height: 200px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  margin-top: 8px;
  margin-bottom: 40px;
  background-repeat: no-repeat;
  img {
    width: 100%;
    height: inherit;
    display: block;
    object-fit: cover;
    margin: auto;
  }
`;

//Div for description main container
export const DMC = styled.div`
  font-size: 14px;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  font-weight: 400;
  line-height: 20px;
`;

//div for description one
export const DO = styled.div`
  padding-bottom: 20px;
`;

//div for description container
export const DOT = styled.div`
  text-align: justify;
`;

//div for full terms and conditions
export const FTC = styled.div`
  font-weight: 700;
  padding-bottom: 8px;
`;
//button for start bet here link
export const SBH = styled.button`
  margin-bottom: 16px;
  background-color: ${({ backgroundColor, casinoTheme }) =>
    casinoTheme ? SUPPLEMENTARY?.casino : backgroundColor};
  height: 40px;
  border-radius: 4px;
  border: none;
  outline: none;
`;

//a for link to event
export const LTE = styled.a`
  color: ${FONT.dark};
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  padding: 16px;
`;

//description title
export const DT = styled.h5`
  height: 24px;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: ${({ fontColor, casinoTheme }) =>
    casinoTheme ? SUPPLEMENTARY?.casino : fontColor};
  margin-bottom: 20px;
`;

export const IMAGE_CONTAINER = styled.img`
  width: 100%;
`;
